.welcome-section {
    padding: 48px 0px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
    max-width: 1250px;
    width: 85%;
}
.welcome-header {
    width: 100%;
    max-width: 600px;
    margin: auto;
    align-items: left;
    align-content: left;

}

.welcome-lbl {
    font-size: 36px;
    font-weight: bold;
    font-family: "Roboto Slab",serif;
    text-align: left;
    width: 100%;
}

.welcome-wrapper {
    margin: 0px auto 0px 0px;
    width: 0%;
}

.welcome-msg {
    width: 100%;
    margin: auto;

}

.welcome-msg p{
    text-align: left;
    font-size: large;
    line-height: 28px;;
    font-family: "Roboto Slab",serif;
    color:rgb(155, 154, 154);

}

.img-div {
    margin:auto;
    width: 100%;
    max-width: 600px;
}

.text-div {
    margin: auto;
    width: 100%;
    max-width: 600px;
}

.welcome-img-style {
    width: 75%;
    object-fit:cover;
    height: 75%;
    margin: auto;
    border-radius: 20px;

}
