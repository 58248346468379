.footer {
    min-height: 150px;
    padding: 16px;
    /* width: 100%; */
}

.footer-name {
    font-size: x-large;
    font-weight: bold;
    font-family: "Roboto Slab",serif;
    padding: 8px;
    text-align: center;
}

.footer-name:hover{
    color: rgb(17, 202, 202);
}

.footer-socials {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-icons {
    color: black;
    padding: 8px;
}

.copywrite {
    width: 85%;
    margin: auto;
    border-top: .1rem solid #e6e6e6;
    margin-top: 8px;
    padding: 4px;
    text-align: center;

}