.page-background {
    background-color: #F5F5F5;
}

.page-content-section {
    width: 85vw;
    margin: auto;
    background-color: white;
}

.page-header-div {
    padding: 32px 0px;
    /* font-family: Helvetica;  */
    font-family: "Roboto Slab",serif;
}

.page-text-div {
    margin: 0px 10%;
    /* padding: 64px 0px; */
}

.page-image-holder {
    width: 100%;
    margin: auto;
}

.page-header-image {
    width: 100%;
    object-fit:cover;
    max-height: 400px;

}

.page-footer-div {
    background-color: white;
}

.project-description {
    padding: 32px 0px;
    margin: 0px;
    font-size: 20px;
    /* font-family: Helvetica;  */
    font-family: "Roboto Slab",serif;
    line-height: 28px;
}

.page-navbar-wrapper {
    position: sticky;
}


.golf-assistant-images-div {
    width: 100%;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.golf-assistant-images-div > img {
    max-width: 250px;
    margin: auto;

}
