.projects-section {

    background-color: #F5F5F5;
    padding: 32px 0px
}

.projects-title-div {
    padding: 24px;
}

.projects-section-title {
    font-size: xx-large;
    font-weight: bold;
    font-family: "Roboto Slab",serif;
}

.projects-content {
    width: 85%;
    max-width: 1250px;
    margin: auto;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;

}

.project-card-container {
    background-color: white;
    border-radius: 20px;
    min-width: 300px;
    max-width: 350px;
    min-height: 300px;
    width: 100%;
    display: grid;
    grid-template-rows: .5fr 2fr 1fr;
    margin: 20px auto;
    transition: all .2s ease-in-out; 
}

.project-card-container:hover{
    box-shadow: 6px 6px 8px 2px rgba(44, 44, 44, 0.1);
    transform: scale(1.1); 
    /* cursor: pointer; */
    color: rgb(17, 202, 202);
}

.title {
    padding: 20px;
}

.title-text {
    font-size: x-large;
    font-weight: bold;
    font-family: "Roboto Slab",serif;
}

.text-holder {
    padding:20px;
}

.description-text {
    text-align: left;
    color:rgb(155, 154, 154);
}


.img-holder {
    width: 100%;
}

.img-style {
    width: 100%;
    object-fit:cover;
    height: 100%;

}


/* .grow-project { 
    transition: all .2s ease-in-out; 
}

.grow-project:hover { 
    transform: scale(1.05); 
    cursor: pointer;
} */

.project-grow { 
    transition: all .2s ease-in-out; 
    }
    
    .project-grow:hover { 
    transform: scale(1.05); 
    }